import Header from "../components/Header";
import { useState, useEffect } from "react";
export default function ProjectDesc() {
    const [isContentWidthSet, setIsContentWidthSet] = useState(false);
    // useEffect(() => {
    //     if (!isContentWidthSet) {
    //         if ((window.innerWidth) < 800) {
    //             // 처음에 한번만 width를 세팅하도록 한다.
    //             setIsContentWidthSet(true)
    //             storage.setItem('contentWidth', window.innerWidth.toString())
    //         } else {
    //             setIsContentWidthSet(true)
    //             storage.setItem('contentWidth', '800')
    //         }
    //     }
    // }, [])
    let storage = sessionStorage;
    let contentWidth = Number(storage.getItem("contentWidth"));
    const onClickToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "auto",
        });
    };
    return (
        <div
            style={{
                width: "100%",
                textAlign: "center",
            }}
        >
            <Header />
            <div className="title">Opticon's Tasks</div>
            <p className="content">
                1. 기획에서 현장까지 건축 프로세스를 관통하는 설계 자동화
                파이프라인
                <br />
                2. 프리패브리케이션 디자인 고유의 가치
                <br />
                3. 건축 상품 브랜딩
            </p>

            <div className="title">이신후</div>
            <div className="content">
                2024 ~ Opticon 대표
                <br />
                <br />
                2011 ~ 2019 서울대학교 건축학과 건축학전공 수석졸업 <br />
                서울대학교 미술대학 조소과, 디자인학부 금속공예과 부전공
                <br />
                <br />
                2019 ~ 2021 솔토지빈 건축사사무소 <br />
                - 염곡동 단독주택
                <br /> - 마산교구청사
                <br /> - 세종시 산림청사
                <br />
                <br />
                2021 ~ 2023 제너레잇
                <br /> - LA Multifamily Housing 자동화 기획 구현
                <br />
                <br />
                2023 ~ 2024 에디트 콜렉티브 <br />
                - 일반 상업지역 공동주택 설계 자동화 기획 구현
                <br />
                - 일반 주거지역 공동주택 설계 자동화 기획 구현
                <br />
                <br />
                2023 ~ 정림재단 컴퓨테이셔널 강연 및 워크샵
                <br />
                <br />
            </div>
            <div
                onClick={onClickToTop}
                className="lettering_option_text_active"
                style={{
                    display: "inline-block",
                    width: 200,
                    height: 45,
                    textAlign: "center",
                    border: "1px solid #0BAA5D",
                    borderRadius: 20,
                }}
            >
                맨 위로 이동하기
            </div>
        </div>
    );
}
