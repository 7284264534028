import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../scss/Common.scss";

export default function Home() {
    const nav = useNavigate();
    const onClickAbout = () => {
        nav(`/about`);
    };
    const onClickWorkShops = () => {
        alert("해당 페이지는 준비중에 있습니다.");
    };
    const onClickContact = () => {
        nav(`/contact`);
    };

    const buttonStyle = {
        fontSize: 20,
        background: "white",
        border: "none",
    };
    let storage = sessionStorage;

    const [isContentWidthSet, setIsContentWidthSet] = useState(false);
    useEffect(() => {
        if (!isContentWidthSet) {
            if (window.innerWidth < 800) {
                // 처음에 한번만 width를 세팅하도록 한다.
                setIsContentWidthSet(true);
                storage.setItem("contentWidth", window.innerWidth.toString());
            } else {
                setIsContentWidthSet(true);
                storage.setItem("contentWidth", "800");
            }
        }
    }, []);
    const contentWidth = Number(storage.getItem("contentWidth"));
    return (
        <div
            style={{
                marginTop: 100,
                textAlign: "center",
            }}
        >
            <div className="main-title">OPTICON</div>
            <div className="sub-title">Optimal Construction Solution</div>
            <div
                style={{
                    marginTop: 70,
                    display: "inline-block",
                }}
            >
                <div
                    style={{
                        width: Math.min(contentWidth - 20, 400),
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <button
                        className="option-buttons"
                        style={buttonStyle}
                        onClick={onClickAbout}
                    >
                        about
                    </button>
                    <div>|</div>
                    <button
                        className="option-buttons"
                        style={buttonStyle}
                        onClick={onClickWorkShops}
                    >
                        works
                    </button>
                    <div>|</div>
                    <button
                        className="option-buttons"
                        style={buttonStyle}
                        onClick={onClickContact}
                    >
                        contact
                    </button>
                    <div></div>
                </div>
            </div>
        </div>
    );
}
