import React from 'react';
import ReactDOM from 'react-dom/client';
import router from './router';
import { RouterProvider, useLocation } from "react-router-dom"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const client = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  // <RouteChangeTracker>
  <React.StrictMode>
    <QueryClientProvider client={client}>
        <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
  // </RouteChangeTracker>
);

