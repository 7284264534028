import Header from "../components/Header"
import { Link, useNavigate } from "react-router-dom";
import "../scss/Common.scss"
import { useState, useEffect } from "react";
const Works = () => {
    const students = ["장유진", "홍채원", "박준민", "장동혁", "김수영"]
    const onClickGithub = () => {
        window.location.assign("https://github.com/sinuli/ooa-2023-1")
    }
    return <div style={{
        marginLeft: 25,
        marginRight: 25,
    }}>
        <div
            style={{
                marginTop: 10,
                marginBottom: 10,
                padding: 0
            }}
            className="content-title"
        >2023-08 여름</div>
        <div
            onClick={onClickGithub}
            style={{ display: 'flex', alignItems: 'center' }}>
            <img width={20} height={20} src="/github.png" alt="" />
            <div className="content" style={{ padding: 4 }}> Github 코드보러가기</div>
        </div>
        {/* <div
            onClick={onClickGithub}
            style={{ display: 'flex', alignItems: 'center' }}>
            <img width={20} height={20} src="/notion.png" alt="" />
            <div className="content" style={{ padding: 4 }}> 수업 히스토리 보러가기</div>
        </div> */}
        <div
            style={{
                marginTop: 20,
                marginBottom: 20,
                display: 'flex',
                justifyContent: 'space-between',

            }}>
            {
                students?.map((student) => (
                    <div
                        onClick={() => { alert("해당 페이지는 준비중에 있습니다.") }}
                        style={{
                            background: "#F3F3F3",
                            // border: '1px solid #000',
                            borderRadius: 15,
                            width: "18%",
                            height: window.innerWidth / 10,
                            paddingTop: window.innerWidth / 15,
                            textAlign: 'center'
                        }}>
                        {student}
                    </div>
                ))
            }
        </div>
    </div>
}
const Curriculum = () => {
    return (
        <>
            <div className="content-title">
                4주간 사전과제
            </div>
            <div className="content">
                1. List 사용해보기<br />
                2. List 활용해서 폴더 이름 일괄처리해보기<br />
                3. Dictionary 활용해보기<br />
                4. 패키지 Shapely Tutorial<br />
                5. 패키지 Pandas Tutorial<br />
                6. Class Tutorial : Room을 정의하기<br />
                7. 자신만의 건축문제 기획하기<br />
            </div>
            <div className="content-title">
                4주간 주 2회 워크샵
            </div>
            <div className="content">
                1주차 1.실습 : 자동화하고 싶은 과제 발표하기<br />
                1주차 1.세션 : 파이썬과 데이터구조, 건축설계자동화 학습법<br />
                <br />
                1주차 2.실습 : 구체화된 기획 발표하기<br />
                1주차 2.세션 : 프로그래밍의 약속, 컨벤션 / Python 패키지<br />
                <br />
                2주차 1.실습 : 기획을 작업단위로 쪼개기 + 함수 만들기<br />
                2주차 1.세션 : Git Github 이론<br />
                <br />
                2주차 2.실습 : 프로그래밍[구현하기]<br />
                2주차 2.세션 : Git Github 세팅 및 공유<br />
                <br />
                3주차 1.실습 : 프로그래밍[구현하기]<br />
                <br />
                3주차 2.실습 : 프로그래밍[클래스]<br />
                <br />
                4주차 1.실습 : 프로그래밍[클래스]<br />
                4주차 1.세션 : 읽기 좋은 코드<br />
                <br />
                4주차 2.실습 : 결과물 공유 및 리뷰<br />
            </div>
        </>
    )
}
export default function Workshops() {
    const nav = useNavigate();

    let storage = sessionStorage;
    const optionList = ["커리큘럼", "작업보기"]
    const [activeOption, setActiveOption] = useState(optionList[0])
    const onClickOption = (option: string) => {
        setActiveOption(option)
    }
    const color1 = "#0BAA5D"
    const optionButton = {
        width: "48%",
        background: '#F3F3F3',
        height: 75,
        borderRadius: '10px'
    }

    const optionActiveButton = {
        width: "48%",
        border: `1px solid ${color1}`,
        background: '#FFFFFF',
        height: 75,
        borderRadius: '10px',
    }

    // const [isContentWidthSet, setIsContentWidthSet] = useState(false);
    // useEffect(() => {
    //     if (!isContentWidthSet) {
    //         if ((window.innerWidth) < 800) {
    //             // 처음에 한번만 width를 세팅하도록 한다.
    //             setIsContentWidthSet(true)
    //             storage.setItem('contentWidth', window.innerWidth.toString())
    //         } else {
    //             setIsContentWidthSet(true)
    //             storage.setItem('contentWidth', '800')
    //         }
    //     }
    // }, [])
    return (<>
        <Header />
        <div
            className="title">Workshops</div >
        <div className="content">
            4주간의 과제와 3주간의 워크샵을 통해서 건축과 접목될 수 있는 프로그래밍 개념을 학습합니다. List, Dictionary 등의 기초 자료형과, 데이터를 다루는 방식을 학습하고, 곧바로 Object(python에서는 Class) 를 다루기 위한 실습으로 연결합니다.
            <br />
            <br />
            설계자동화 실무가 어떻게 이뤄지는지를 파악하고, 기획, 개발, 협업에 대한 내용을 아울러 교육합니다. Git, GitHub등의 협업툴, Python Library인 shapely, pandas, numpy, ezdxf 라이노 플러그인 사용법을 실습 위주로 배웁니다.
            <br />
            <br />
            OOP는 1년 정도의 개발 시간이 있어야 생각 속에 깊게 녹아서 자동화에 적용할 수 있게 됩니다. 이 워크샵의 최종 목적은 OOP를 자유롭게 활용하는 Object Oriented Architecture 이지만, 실질적으로 4주 안에 그정도 수준에 이르기는 어렵습니다.
            지속적인 후속 워크샵 개발을 통해서 설계자동화 기획자, 프로그래머 서비스 제작자를 양성하는 것을 목적으로 합니다.
        </div>
        <div >
            <div
                style={{
                    marginLeft: 25,
                    marginRight: 25,

                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                {optionList?.map((option: string) => (
                    <div
                        style={option === activeOption ? optionActiveButton : optionButton}
                        onClick={() => { onClickOption(option) }}>
                        <p className={option === activeOption ?
                            "lettering_option_text_active" : "lettering_option_text"}>
                            {option}</p>
                    </div>
                ))}
            </div>
            {activeOption === "커리큘럼" ? <Curriculum /> :
                <Works />}
        </div>
    </>)
}