import Header from "../components/Header";

export default function Contact() {
    return (
        <div
            style={{
                width: "100%",
                textAlign: "center",
            }}
        >
            <Header />
            <div className="title">Contact</div>
            <div className="content">
                워크샵 혹은 설계자동화 관련 커피챗과 프로젝트 문의는 언제나
                환영합니다.
                <br />
                Email 로 연락주시면 1~2일 정도 안으로 답변 가능합니다. <br />
                <br />
                Email : opticon.master@gmail.com
                <br />
                Phone : 01072477712
            </div>
        </div>
    );
}
