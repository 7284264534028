import { useNavigate } from "react-router-dom";
import "../scss/Common.scss";
export default function Header() {
    const nav = useNavigate();
    let storage = sessionStorage;
    let contentWidth = Number(storage.getItem("contentWidth"));
    const onClickHome = () => {
        nav(`/`);
    };
    const onClickApply = () => {
        const apply = "https://forms.gle/1tN74ioRVLakV6ed9";
        window.location.assign(apply);
    };
    return (
        <div
            className="header"
            style={{
                paddingLeft: 20,
                paddingRight: 20,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <div onClick={onClickHome}>Opticon</div>
        </div>
    );
}
