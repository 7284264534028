import { useNavigate } from "react-router-dom";
import React from "react";

export default function NotFound() {
    const nav = useNavigate();
    const onClickButton = () => {
        nav(`/`);
    };
    return (
        <div>
            <h1>Page not found</h1>
            <p>페이지에 뭔가 문제가 있습니다. 주문을 다시 시작해주세요</p>
            <button onClick={onClickButton}>Go Home</button>
        </div>
    );
}
